<script>
import form from './form'

export default {
  extends: form,

  methods: {
    onSubmit() {
      this.$apiMethods.districts.create(this.form)
        .then(() => {
          this.successSubmit({ message: 'Федеральный округ успешно создан', routeName: 'districts' })
        })
        .finally(() => { this.afterSubmit() })
    }
  }
}
</script>